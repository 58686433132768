<template>
	<div>
		<b-row style="padding-bottom: 1em;">
			<b-col lg="12">
				<b-tabs class="flex-nowrap" align="center" justified v-model="statistic_index" @input="doRequest">
					<!-- <b-tab :title="'Период: ' + this.$store.state.appstats.diff_text" /> -->
					<b-tab v-for="stat in statistic_types" :key="stat.key" :title="stat.value" />
				</b-tabs>
				<!-- {{ $store.state.appstats.start_date }} -- {{ $store.state.appstats.end_date }} -->
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12">
				<date-tabs v-on:date-changed="doRequest" />
				<!-- {{ $store.state.appstats.start_date }} -- {{ $store.state.appstats.end_date }} -->
			</b-col>
		</b-row>
		<b-overlay :show="loading" :class="{ isLoading: loading }">
			<b-row>
				<b-col lg="3" style="max-width: 485px; min-width: 300px">
					<c-pie :key="key" :data="all_queries_total" />
				</b-col>
				<b-col lg="9">
					<c-line :key="key" :data="all_queries_range" />
				</b-col>
			</b-row>

			<b-row v-if="statistic_type == 'all'">
				<b-col lg="3" style="max-width: 485px; min-width: 300px">
					<c-pie :key="key" :data="query_types_total" />
				</b-col>
				<b-col lg="9">
					<c-line :key="key" :data="query_types_range" />
				</b-col>
			</b-row>

			<b-row>
				<b-col lg="3" style="max-width: 485px; min-width: 300px">
					<c-pie v-if="statistic_type == 'liveness_comparison'" :key="key" :data="processed_combo_total" />
					<c-pie v-else :key="key" :data="processed_total" />
				</b-col>
				<b-col lg="9">
					<c-line v-if="statistic_type == 'liveness_comparison'" :key="key" :data="processed_combo_range" />
					<c-line v-else :key="key" :data="processed_range" />
				</b-col>
			</b-row>

			<b-row>
				<b-col lg="4" style="min-width: 400px">
					<c-bar :key="key" :data="error_types_total" />
				</b-col>
				<b-col lg="8">
					<c-v-bar :key="key" :data="error_types_range" />
				</b-col>
			</b-row>
		</b-overlay>
	</div>
</template>

<script>
import { BRow, BCol, BTabs, BTab, BOverlay } from 'bootstrap-vue'

import CardStatisticTotal from './statistics/CardStatisticTotal.vue'
import LineChart from './statistics/LineChart.vue'
import DateTabs from './statistics/DateTabs.vue'
import GroupBarChart from './statistics/GroupBarChart.vue'
import CPie from './statistics/CPie.vue'
import CBar from './statistics/CBar.vue'
import CVBar from './statistics/CVBar.vue'
import CLine from './statistics/CLine.vue'

const REASONS_GROUP_MAP = [
	{ key: 'error_grayscale', target: 0, name: 'Цветность' },
	{ key: 'error_blurriness', target: 1, name: 'Размытость' },
	{ key: 'error_multi_face', target: 2, name: 'Количество лиц' },
	{ key: 'error_eyes_not_detected', target: 3, name: 'Глаза' },
	{ key: 'error_head_rotation', target: 4, name: 'Поворот' },
	{ key: 'error_face_not_found', target: 5, name: 'Нет лица' },
	{ key: 'error_extreme_lighting', target: 6, name: 'Световые условия' },
	{ key: 'error_myid_auth', target: 7, name: 'Ошибка авторизации MYID' },
	{ key: 'error_improper_image', target: 8, name: 'Ошибка изображения' },
	{ key: 'error_parse_image', target: 8, name: 'Ошибка изображения' },
	{ key: 'error_image_parser_server', target: 9, name: 'Ошибка cервера' },
	{ key: 'error_image_parser_connection', target: 9, name: 'Ошибка cервера' },
	{ key: 'error_myid_server', target: 9, name: 'Ошибка cервера' },
	{ key: 'error_myid_connection', target: 9, name: 'Ошибка cервера' },
	{ key: 'error_myid_client_has_blocked', target: 9, name: 'Ошибка cервера' },
	{ key: 'error_myid_client_has_no_paid_requests', target: 9, name: 'Ошибка cервера' },
	{ key: 'error_not_fully_shown', target: 5, name: 'Нет лица' },
	{ key: 'error_comparison_face_not_found', target: 5, name: 'Нет лица' },
	{ key: 'error_artifact_detected', target: 10, name: 'Сторонние объекты' },
]

const REASONS_COMPARISON_GROUP_MAP = [
	{ key: 'error_face_not_found', target: 0, name: 'Нет лица' },
	{ key: 'error_not_fully_shown', target: 0, name: 'Нет лица' },
	{ key: 'error_comparison_face_not_found', target: 0, name: 'Нет лица' },
	{ key: 'error_myid_auth', target: 1, name: 'Ошибка авторизации MYID' },
	{ key: 'error_improper_image', target: 2, name: 'Ошибка изображения' },
	{ key: 'error_parse_image', target: 2, name: 'Ошибка изображения' },
	{ key: 'error_image_parser_server', target: 3, name: 'Ошибка cервера' },
	{ key: 'error_image_parser_connection', target: 3, name: 'Ошибка cервера' },
	{ key: 'error_myid_server', target: 3, name: 'Ошибка cервера' },
	{ key: 'error_myid_connection', target: 3, name: 'Ошибка cервера' },
	{ key: 'error_myid_client_has_blocked', target: 3, name: 'Ошибка cервера' },
	{ key: 'error_myid_client_has_no_paid_requests', target: 3, name: 'Ошибка cервера' },
	{ key: 'error_artifact_detected', target: 10, name: 'Сторонние объекты' },
]

/* eslint-disable quote-props */
const REASONS_LOOKUP = {
	Цветность: 'isColourful',
	Размытость: 'blurriness',
	'Количество лиц': 'recognizedFaces',
	Глаза: 'openEyes',
	Поворот: 'faceAlignment',
	'Нет лица': 'errorInternal',
	'Световые условия': 'errorInternal',
	'Ошибка авторизации MYID': 'errorInternal',
	'Ошибка изображения': 'errorInternal',
	'Ошибка cервера': 'errorInternal',
	'Клиент заблокирован': 'errorInternal',
	'Закончились запросы': 'errorInternal',
	// 'Сторонние объекты': '', // no such filter is available in API for now
}
/* eslint-enable quote-props */

export default {
	components: {
		BRow,
		BCol,
		BTabs,
		BTab,
		CardStatisticTotal,
		LineChart,
		DateTabs,
		GroupBarChart,
		BOverlay,
		CPie,
		CBar,
		CLine,
		CVBar,
	},

	computed: {
		statistic_type() {
			return this.statistic_types[this.statistic_index].key
		},
	},

	data() {
		return {
			loading: false,
			key: 0,
			statistic_index: 0,
			statistic_types: [
				{ key: 'all', value: 'Все' },
				{ key: 'liveness_only', value: 'Liveness' },
				{ key: 'comparison_only', value: 'Comparison' },
				{ key: 'liveness_comparison', value: 'Combo' },
			],
			all_queries_total: {
				title: 'Все запросы',
				series: [],
				options: { labels: ['Обраб-ные', 'Необр-ные'], colors: ['#9C27B0', '#FF9800'] },
			},
			processed_total: {
				title: 'Обработанные запросы',
				series: [],
				options: { labels: ['Успешные', 'Неуспешные'], colors: ['#4CAF50', '#F44336'] },
			},
			all_queries_range: {
				title: 'Все запросы',
				intervals: {},
				options: {
					colors: ['#00BCD4', '#9C27B0', '#FF9800'],
				},
			},
			processed_range: {
				title: 'Обработанные запросы',
				intervals: {},
				options: {
					colors: ['#9C27B0', '#4CAF50', '#F44336'],
				},
			},

			processed_combo_total: {
				title: 'Обработанные запросы',
				series: [],
				options: {
					labels: ['Успешные', 'Полностью неуспешные', 'Liveness - неуспешные', 'Comparison - неуспешные'],
					colors: ['#4caf50', '#f44336', '#00a2e8', '#ffaec9'],
				},
			},
			processed_combo_range: {
				title: 'Обработанные запросы',
				intervals: {},
				options: {
					colors: ['#4caf50', '#f44336', '#00a2e8', '#ffaec9'],
				},
			},

			error_types_total: {
				title: 'Типы ошибок',
				series: [],
				options: {
					chart: {
						type: 'bar',
						events: {
							click: (a, b, chart) => {
								if (
									chart.globals.seriesX &&
									chart.globals.seriesX[0] &&
									chart.globals.seriesX[0][chart.dataPointIndex] &&
									REASONS_LOOKUP[chart.globals.seriesX[0][chart.dataPointIndex]]
								) {
									let id = REASONS_LOOKUP[chart.globals.seriesX[0][chart.dataPointIndex]]
									this.$router.push({
										name: 'queries', //use name for router push
										params: { id },
									})
								}
							},
						},
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: true,
						},
					},
					dataLabels: {
						enabled: false,
					},
					xaxis: {
						type: 'category',
					},
					colors: [({ dataPointIndex }) => this.resolveErrorTypeColors(dataPointIndex)],
				},
			},
			error_types_range: {
				title: 'Типы ошибок',
				intervals: {},
				options: {
					colors: [({ seriesIndex }) => this.resolveErrorTypeColors(seriesIndex)],
				},
			},
			query_types_total: {
				title: 'Типы запросов',
				series: [],
				options: { labels: ['Liveness', 'Comparison', 'Combo'] },
			},
			query_types_range: {
				title: 'Типы запросов',
				intervals: {},
				options: {},
			},
		}
	},

	methods: {
		summary(data, requestType) {
			let aqt = [0, 0]
			let pt = [0, 0]
			let ett = []
			let pct = [0, 0, 0, 0]
			let qtt = [0, 0, 0]

			const MAP = requestType == 'comparison_only' ? REASONS_COMPARISON_GROUP_MAP : REASONS_GROUP_MAP

			// Disable rules for this specific case: `data` is a plain object parsed from server response.
			/* eslint-disable no-restricted-syntax, guard-for-in */
			for (const date in data) {
				const element = data[date]
				aqt[0] += element.total
				aqt[1] += element.unprocessed

				if (requestType === 'liveness_only') pt[0] += element.total_is_alive || 0
				else if (requestType === 'comparison_only') pt[0] += element.total_is_recognized || 0
				const comboPassed =
					element.total_comparison_liveness_passed || element.total_liveness_comparison_passed || 0
				if (requestType === 'liveness_comparison') pt[0] += comboPassed
				else if (requestType === 'all') {
					const livenessPassed = (element.total_is_alive || 0) - comboPassed
					const comparisonPassed = (element.total_is_recognized || 0) - comboPassed
					/**
					 * Term "успешный запрос" ("successful request") has two meanings in the project:
					 * 1. technical — request was processed successfully;
					 * 2. business — request was processed successfully AND has passed all checks.
					 *
					 * Charts should depict business meaning.
					 */
					const totalPassed = comboPassed + livenessPassed + comparisonPassed

					pt[0] += totalPassed
				}

				for (const reason of MAP) {
					if (!ett[reason.target]) ett[reason.target] = 0
					ett[reason.target] += element.count[reason.key]
				}

				// pt[1] += fails
				pct[0] += element.total_comparison_liveness_passed || 0
				pct[1] += element.total_liveness_not_comparison_passed || 0
				pct[2] += element.total_liveness_passed_comparison_not || 0
				pct[3] += element.total_comparison_liveness_not_passed || 0

				qtt[0] += element.total_liveness_only || 0
				qtt[1] += element.total_comparison_only || 0
				qtt[2] += element.total_liveness_comparison || 0
			}
			/* eslint-enable no-restricted-syntax, guard-for-in */

			ett = ett.filter(reason => reason !== undefined)
			MAP.forEach(reason => {
				if (!ett[reason.target]?.x) {
					ett[reason.target] = {
						x: reason.name,
						y: ett[reason.target] || 0,
					}
				}
			})

			ett = [{ name: 'Ошибки', data: ett }]
			aqt[0] -= aqt[1]
			pt[1] = aqt[0] - pt[0]

			this.all_queries_total.series = aqt
			this.processed_total.series = pt
			this.error_types_total.series = ett
			this.processed_combo_total.series = pct
			this.query_types_total.series = qtt
		},

		range(data, type, requestType) {
			let aqr = [
				{ data: [], name: 'Всего' },
				{ data: [], name: 'Обработанные' },
				{ data: [], name: 'Не обработанные' },
			]
			let pr = [
				{ data: [], name: 'Всего обработано' },
				{ data: [], name: 'Успешные' },
				{ data: [], name: 'Неуспешные' },
			]
			let pcr = [
				{ data: [], name: 'Успешные' },
				{ data: [], name: 'Полностью неуспешные' },
				{ data: [], name: 'Liveness - неуспешные' },
				{ data: [], name: 'Comparison - неуспешные' },
			]
			let etr = []
			let qtr = [
				{ data: [], name: 'Всего' },
				{ data: [], name: 'Liveness' },
				{ data: [], name: 'Comparison' },
				{ data: [], name: 'Combo' },
			]
			const MAP = requestType == 'comparison_only' ? REASONS_COMPARISON_GROUP_MAP : REASONS_GROUP_MAP

			// Disable rules for this specific case: `data` is a plain object parsed from server response.
			/* eslint-disable no-restricted-syntax, guard-for-in */
			function getOffset() {
				const date = new Date()
				let offset = date.getTimezoneOffset() * -1
				offset = (offset < 0 ? '+' : '-') + String(Math.abs(offset / 60)).padStart(2, '0') + ':00'
				return offset
			}
			for (let date in data) {
				const obj = data[date]
				date = date.split('+')[0] + getOffset()
				console.log(date)
				aqr[0].data.push({ x: date, y: obj.total || 0 })
				aqr[1].data.push({ x: date, y: (obj.total || 0) - (obj.unprocessed || 0) })
				aqr[2].data.push({ x: date, y: obj.unprocessed || 0 })

				MAP.forEach(reason => {
					if (!etr[reason.target]) etr[reason.target] = { data: [], type: 'column', name: reason.name }
					if (etr[reason.target].data.find(d => d.x == date)) {
						etr[reason.target].data[etr[reason.target].data.length - 1].y += obj.count[reason.key] || 0
					} else {
						etr[reason.target].data.push({ x: date, y: obj.count[reason.key] || 0 })
					}
				})
				pr[1].data.push({ x: date, y: obj.total_is_alive || 0 })
				pr[2].data.push({ x: date, y: (obj.total || 0) - (obj.unprocessed || 0) - (obj.total_is_alive || 0) })
				pr[0].data.push({ x: date, y: (obj.total || 0) - (obj.unprocessed || 0) })

				pcr[0].data.push({ x: date, y: obj.total_comparison_liveness_passed || 0 })
				pcr[1].data.push({ x: date, y: obj.total_liveness_not_comparison_passed || 0 })
				pcr[2].data.push({ x: date, y: obj.total_liveness_passed_comparison_not || 0 })
				pcr[3].data.push({ x: date, y: obj.total_comparison_liveness_not_passed || 0 })

				qtr[0].data.push({
					x: date,
					y:
						(obj.total_liveness_only || 0) +
						(obj.total_comparison_only || 0) +
						(obj.total_liveness_comparison || 0),
				})
				qtr[1].data.push({ x: date, y: obj.total_liveness_only || 0 })
				qtr[2].data.push({ x: date, y: obj.total_comparison_only || 0 })
				qtr[3].data.push({ x: date, y: obj.total_liveness_comparison || 0 })
			}
			/* eslint-enable no-restricted-syntax, guard-for-in */
			etr = etr.filter(a => a) // why?

			this.all_queries_range.intervals[type] = aqr
			this.processed_range.intervals[type] = pr
			this.processed_combo_range.intervals[type] = pcr
			this.error_types_range.intervals[type] = etr
			this.query_types_range.intervals[type] = qtr
		},

		resolveErrorTypeColors(index) {
			if (this.statistic_type == 'comparison_only') return ['#795548', '#FFEB3B', '#FF9800', '#F44336'][index % 4]
			return [
				'#00BCD4',
				'#4CAF50',
				'#9C27B0',
				'#3F51B5',
				'#CDDC39',
				'#795548',
				'#2196F3',
				'#FFEB3B',
				'#FF9800',
				'#F44336',
			][index % 10]
		},

		doRequest() {
			this.loading = true
			this.all_queries_range.intervals = {}
			this.processed_range.intervals = {}
			this.error_types_range.intervals = {}
			for (let index = 0; index < this.$store.state.appstats.ranges.length; index++) {
				let params = {
					start_date: this.$store.state.appstats.start_date,
					end_date: this.$store.state.appstats.end_date,
					group_by: this.$store.state.appstats.ranges[index].value,
					statistic_type: this.statistic_type,
				}
				this.$http
					.get((process.env.VUE_APP_BACKEND || '') + '/api/dashboard/', { params })
					.then(res => {
						this.loading = false
						this.summary(res.data, params.statistic_type)
						this.range(res.data, params.group_by, params.statistic_type)
						this.key++
					})
					.catch(e => {
						console.log(e)
						if (e.response.status == 401) router.push('login')
						this.loading = false
					})
			}
		},
	},

	created() {
		this.doRequest()
	},
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/chart-apex.scss';
.isLoading {
	max-height: 60vh;
	overflow: hidden;
}
</style>
